import React from 'react';
import { LoadMoreFn, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';
import { ConcreteRequest, OperationType } from 'relay-runtime';

import CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query, {
  CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$data,
  CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$key,
} from './__generated__/CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query.graphql';

export interface CompanySearchLoaderOnLoadFn<T extends OperationType> {
  (
    paginationData: CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$data,
    queryData: T['response'],
    hasNext: boolean,
    loadNext: LoadMoreFn<T>
  ): void;
}

interface CompanySearchLoaderProps<T extends OperationType> {
  gqlQuery: ConcreteRequest;
  queryReference: PreloadedQuery<T, Record<string, unknown>>;
  onLoad: CompanySearchLoaderOnLoadFn<T>;
  onLoadingNext: () => void;
}

export const CompanySearchLoader = <T extends OperationType>({
  gqlQuery,
  queryReference,
  onLoad,
  onLoadingNext,
}: CompanySearchLoaderProps<T>) => {
  const queryData = usePreloadedQuery<T>(
    gqlQuery,
    queryReference
  ) as CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$key;

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    T,
    CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$key
  >(CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query, queryData);

  React.useEffect(() => {
    if (data) {
      onLoad(data, queryData, hasNext, loadNext);
    }
  }, [data, queryData, hasNext, loadNext, onLoad]);

  React.useEffect(() => {
    if (isLoadingNext) {
      onLoadingNext();
    }
  }, [onLoadingNext, isLoadingNext]);

  return null;
};
