import React, { useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import { styled, keyframes, Banner, Text, Heading, Icon } from '@attentive/picnic';
import {
  GraphqlRequestFailureError,
  GraphqlPermissionDeniedError,
  GraphqlIncompleteDataError,
  GraphqlScalarDeserializationError,
} from '@attentive/data';

import {
  GraphqlIncompleteDataErrorMessage,
  GraphqlPermissionDeniedErrorMessage,
  GraphqlRequestFailureErrorMessage,
  GraphqlScalarDeserializationErrorMessage,
} from './GraphqlErrorMessages';
import { CLIENT_UI_ERROR_ID } from '../Logger/Logger';

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledItem = styled(Accordion.Item, {
  overflow: 'hidden',
  marginTop: 1,
});

const Root = styled(Accordion.Root, {
  minHeight: '$space10',
});

const AccordionContent = styled(Accordion.Content, {
  '&[data-state="open"]': { animation: `${open} 300ms ease-out` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-out` },
});

const StyledTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
  padding: '0 $space4',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: `0 1px 0 $shadow3`,
  cursor: 'pointer',
  '&:hover': { color: '$textHover' },
});

const StyledIcon = styled(Icon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});

const ErrorMessage: React.FC<{ error: Error }> = ({ error }) => {
  if (error instanceof GraphqlRequestFailureError) {
    return <GraphqlRequestFailureErrorMessage error={error} />;
  } else if (error instanceof GraphqlPermissionDeniedError) {
    return <GraphqlPermissionDeniedErrorMessage error={error} />;
  } else if (error instanceof GraphqlIncompleteDataError) {
    return <GraphqlIncompleteDataErrorMessage error={error} />;
  } else if (error instanceof GraphqlScalarDeserializationError) {
    return <GraphqlScalarDeserializationErrorMessage error={error} />;
  }

  return (
    <Text>
      <strong>Error Message:</strong> {String(error)}
    </Text>
  );
};

interface ErrorDetailsAccordionProps {
  error: Error;
  defaultOpen?: boolean;
}

export const ErrorDetailsAccordion: React.FC<ErrorDetailsAccordionProps> = ({
  error,
  defaultOpen = false,
}) => {
  // TODO: Remove the `useState` when we upgrade radix.
  // The use of `isOpen` is a workaround for the fact
  // that v0.0.18 of the Accordion component doesn't support
  // the 'collapsible' prop.
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleAccordionHeaderClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <Root
      type="single"
      onValueChange={() => {
        setIsOpen(!isOpen);
      }}
      value={isOpen ? 'item-1' : ''}
    >
      <StyledItem value="item-1">
        <Accordion.Header>
          <StyledTrigger onClick={handleAccordionHeaderClick}>
            <Heading variant="lg" as="h4">
              Error details
            </Heading>
            <StyledIcon name="ChevronUp" />
          </StyledTrigger>
        </Accordion.Header>
        <AccordionContent>
          <Banner
            variant="error"
            dismissible={true}
            css={{ textAlign: 'left' }}
            onDismiss={() => setIsOpen(false)}
          >
            <Text>
              <strong>Error Id:</strong> {CLIENT_UI_ERROR_ID}
            </Text>
            <Text>
              <strong>User Agent:</strong> {navigator.userAgent}
            </Text>
            <ErrorMessage error={error} />
          </Banner>
        </AccordionContent>
      </StyledItem>
    </Root>
  );
};
