import {
  Badge,
  Heading,
  PicnicCss,
  Popover,
  PopoverContentProps,
  styled,
  Text,
} from '@attentive/picnic';
import React from 'react';

const PU_WIDTH = '200px';

export const LiveChat = styled('button', {
  position: 'relative',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '$radius1',
  display: 'flex',
  alignItems: 'center',
  focusVisible: '$focus',
  padding: 0,
});

export const LiveChatText = styled(Text, {
  cursor: 'pointer',
  position: 'relative',

  '&:hover': {
    color: '$textHover',
  },
});

export const ChatStatusIndicator = styled('i', {
  backgroundColor: '$bgAccentDark',
  width: '$size2',
  height: '$size2',
  borderRadius: '$radiusMax',

  variants: {
    active: {
      true: {
        backgroundColor: '$bgSuccessAccent',
      },
    },
  },
});

export const InlineUnreadChatBadge = ({
  unreadCount,
  css,
}: {
  unreadCount: number;
  css?: PicnicCss;
}) => {
  return (
    <Badge variant="primary" position="inline" css={css} data-testid="zendesk-unreadcount-badge">
      {unreadCount}
    </Badge>
  );
};

export const RaisedUnreadChatBadge = ({
  unreadCount,
  css,
}: {
  unreadCount: number;
  css?: PicnicCss;
}) => {
  return (
    <Badge variant="primary" position="raised" css={css} data-testid="zendesk-unreadcount-badge">
      {unreadCount}
    </Badge>
  );
};

export const ZendeskPopoverContent = (props?: PopoverContentProps) => {
  return (
    <Popover.Content showCloseButton={false} css={{ width: PU_WIDTH }} {...props}>
      <Heading variant="sm" css={{ whiteSpace: 'nowrap' }}>
        Live chat hours
      </Heading>
      <Text variant="caption" css={{ mt: '$space2' }}>
        9:00 AM - 9:00 PM ET
        <br />
        Monday - Friday
      </Text>
    </Popover.Content>
  );
};
