import { atom } from 'jotai';

export const GRAPHQL_API_ORIGIN_KEY = 'graphqlApiOrigin';

export const graphqlApiOriginAtom = atom<string>(
  'https://www.example.com/GRAPHQL_FAILED_TO_INITIALIZE'
);

export const graphqlApiUrlRWAtom = atom<string>(
  'https://www.example.com/GRAPHQL_FAILED_TO_INITIALIZE'
);
export const graphqlApiAtom = atom((get) => get(graphqlApiUrlRWAtom));
