import { atom, useSetAtom, useAtomValue } from 'jotai';

export const showNotificationsMenuAtom = atom(false);

export const useShowNotificationsMenu = () => {
  const setAtomValue = useSetAtom(showNotificationsMenuAtom);
  const atomValue = useAtomValue(showNotificationsMenuAtom);

  return [atomValue, setAtomValue] as const;
};
