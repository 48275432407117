import { useAtomValue } from 'jotai';
import { Role, Scope, Permission } from '@attentive/data';
import { rolesAtom, scopesAtom, permissionsAtom } from './authorization-data-atom';
import { LifecycleStatus, companyLifecycleAtom } from './company-lifecycle-atom';
import { AppStore } from './app-store';

/**
 * Returns the current user's list of roles for the current company.
 */
export const useRoles = (): Set<Role> => useAtomValue(rolesAtom);
export const getRoles = () => AppStore.store.get(rolesAtom);

/**
 * Returns the current user's list of scopes for the current company.
 */
export const useScopes = (): Set<Scope> => useAtomValue(scopesAtom);

export const checkPermission =
  (permissions: Record<Permission, boolean>) =>
  (permission: Permission): boolean =>
    permissions[permission];

/**
 * Returns a hash of all permissions, and whether the user has each
 */
export const usePermissions = (): Record<Permission, boolean> => useAtomValue(permissionsAtom);

/**
 * Returns whether a user has permission to access a specific permission-action
 */
export const usePermission = (permission: Permission): boolean =>
  checkPermission(usePermissions())(permission);
/**
 * Returns a hash of all permissions, and whether the user has each
 */
export const getPermissions = (): Record<Permission, boolean> =>
  AppStore.store.get(permissionsAtom);

/**
 * Returns whether a user has permission to access a specific permission-action
 */
export const getPermission = (permission: Permission): boolean =>
  checkPermission(getPermissions())(permission);

export const useCompanyLifecycle = (): LifecycleStatus => useAtomValue(companyLifecycleAtom);
