import { atom } from 'jotai';

export enum OperatingModes {
  Nominal = 'nominal',
  Emergency = 'emergency',
}

export type OperatingMode = typeof OperatingModes.Nominal | typeof OperatingModes.Emergency;

export const operatingModeRWAtom = atom<OperatingMode>(OperatingModes.Nominal);
export const operatingModeAtom = atom((get) => get(operatingModeRWAtom));
