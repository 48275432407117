import { v4 as uuidv4 } from 'uuid';

export type Callback<T = void> = (payload: Payload) => T;
export type EventCallback<T> = (evt: CustomEvent<T>) => void;
// We don't have constraints around what a payload can be.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Payload = any;

// We don't have constraints around what a callback result can be.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallbackResult = any;

export enum PublishTypes {
  CurrentUserUpdated = 'user:updated',
  CreateToast = 'toast:create',
}

interface PublishDetail {
  detail: {
    __uid: string;
  };
}

/**
 * EventBus static class.
 *
 * The `EventBus` is a singleton class that allows inter-app communication. The bus uses
 * events to send messages between apps using the DOM as the transport layer. The `EventBus`
 * supports two types of communication, "pub/sub" or "requests".
 *
 * "Pub/sub" is a common pattern for event driven systems. A component subscribes to an event
 * type, and another publishes the event to the components that are listening.
 *
 * "Requests" uses two way communication to "request" data from one component, the recipient
 * gets the requests and replies back with an answer.
 *
 * @static
 * @deprecated the EventBus is no longer needed.
 */
export abstract class EventBus {
  // Only used by req/res system
  private static handlerMap = new Map<Callback<Payload>, Callback>();

  /**
   * Subscribes a callback handler to the EventBus.
   *
   * @param {string} eventName
   * @param {Function} callback
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  public static subscribe<T>(eventName: string, callback: EventCallback<T>) {
    document.addEventListener(eventName, callback as EventListener);
  }

  /**
   * Unsubscribes a callback handler from the EventBus.
   *
   * @param {string} eventName
   * @param {Function} callback
   *
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  public static unsubscribe<T>(eventName: string, callback: EventCallback<T>) {
    document.removeEventListener(eventName, callback as EventListener);
  }

  /**
   * Publishes an event to the EventBus.
   *
   * @param {string} eventName
   * @param {any} payload
   *
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  public static publish<T = Payload>(eventName: string, payload?: T) {
    if (window.CustomEvent) {
      const event = new CustomEvent<Payload>(eventName, { detail: payload });
      document?.dispatchEvent(event);
    } else {
      const event = document?.createEvent('CustomEvent');
      event.initCustomEvent(eventName, true, true, payload);
      document.dispatchEvent(event);
    }
  }

  /**
   * Makes an asynchronous request to the provider.
   *
   * **NOTE:** If a timeout is not specified, and no request is invoked
   * with the matching requestName, the returned Promise will never resolve
   *
   * @param {string} requestName
   * @param {any} [payload]
   * @param {number} [timeout]
   * @returns `Promise`
   *
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  public static async request(
    requestName: string,
    payload?: Payload,
    timeout?: number
  ): Promise<Payload> {
    const id = uuidv4();
    // We have no contract around what can be requested by an Eventbus
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve, reject) => {
      const replyEventName = `${requestName}:${id}`;
      let isResolved = false;
      const replySubscription = ({ detail }: { detail: Payload }) => {
        const { __error, result } = detail;
        if (__error) {
          reject(__error);
          return;
        }
        resolve(result);
        isResolved = true;
        this.unsubscribe(replyEventName, replySubscription);
      };
      this.subscribe(replyEventName, replySubscription);
      this.publish(requestName, { ...payload, __uid: id });

      if (timeout && !isResolved) {
        setTimeout(() => {
          reject(new Error(`Request timed out after ${timeout} ms`));
        }, timeout);
      }
    });
  }

  /**
   * Invokes a callback on a request with a target request name
   * and passes the result of the callback, if any, to the Promise
   * returned by the request
   *
   * If an error occurs in the callback, the Promise returned
   * by the request will be rejected
   *
   * @param { string } requestName
   * @param { Function } callback
   *
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  // We have no contract around what can be requested by an Eventbus
  public static addRequestHandler(requestName: string, callback: Callback<CallbackResult>) {
    const handler = async ({ detail: { __uid, ...payload } }: PublishDetail) => {
      const replyEventName = `${requestName}:${__uid}`;
      try {
        const result = await Promise.resolve(callback(payload));
        this.publish(replyEventName, { result, __uid });
      } catch (e) {
        this.publish(replyEventName, { __error: e });
        console.error('Error in EventBus reply', e);
      }
    };
    this.handlerMap.set(callback, handler);
    this.subscribe(requestName, handler);
  }

  /**
   * Removes callback handler on a request given the request name, and
   * callback signature.
   *
   * @param {string} requestName
   * @param {Function} callback
   *
   * @public
   * @static
   * @deprecated the EventBus is no longer needed.
   */
  public static removeRequestHandler(requestName: string, callback: Callback<CallbackResult>) {
    const handler = this.handlerMap.get(callback);
    this.handlerMap.delete(callback);
    if (handler) {
      this.unsubscribe(requestName, handler);
    }
  }
}
