import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigNO: LocaleConfig = {
  locale: Locale.noNO,
  currencyConfig: {
    currencyCode: CurrencyCode.NO,
  },
  optOutLanguageRequired: false,
};
