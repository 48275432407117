import { atom, useAtomValue } from 'jotai';
import { ApiDataSource } from '@attentive/data';

export const apiDataSourceAtom = atom<ApiDataSource>(ApiDataSource.Real);

export const useApiDataSource = () => {
  return useAtomValue(apiDataSourceAtom);
};

export const apiHostOverriddenAtom = atom(false);
