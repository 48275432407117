import React, { useContext } from 'react';

import { LazyMfeKeys, AppKeys } from '../types';

const projectNameContext = React.createContext<LazyMfeKeys | AppKeys | undefined>(undefined);

export const ProjectNameProvider = projectNameContext.Provider;

export const useProjectName = () => {
  const ctx = useContext(projectNameContext);

  if (ctx === undefined) {
    throw new Error('useProjectNameContext must be a child of <ProjectContext>');
  }

  return ctx;
};
