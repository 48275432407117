import { createStore } from 'jotai/vanilla';

let _appStore: ReturnType<typeof createStore> = createStore();

export const AppStore = {
  get store() {
    return _appStore;
  },
  reset: () => {
    _appStore = createStore();
  },
};
