/**
 * @generated SignedSource<<babb113e258aaf93addb803827960658>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanySearchFilter = {
  includeReadonly?: boolean | null;
  searchTerm?: string | null;
};
export type CompanySearchDropdownAcoreUtilsQuery$variables = {
  companyId: string;
  count: number;
  cursor?: string | null;
  filter?: CompanySearchFilter | null;
  hasSelectedCompany: boolean;
};
export type CompanySearchDropdownAcoreUtilsQuery$data = {
  readonly selectedCompany?: {
    readonly id: string;
    readonly name?: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query">;
};
export type CompanySearchDropdownAcoreUtilsQuery$rawResponse = {
  readonly companies: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: "Company";
        readonly id: string;
        readonly internalId: number;
        readonly name: string;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly selectedCompany: {
    readonly __typename: "Company";
    readonly id: string;
    readonly name: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type CompanySearchDropdownAcoreUtilsQuery = {
  rawResponse: CompanySearchDropdownAcoreUtilsQuery$rawResponse;
  response: CompanySearchDropdownAcoreUtilsQuery$data;
  variables: CompanySearchDropdownAcoreUtilsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSelectedCompany"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanySearchDropdownAcoreUtilsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query"
      },
      {
        "condition": "hasSelectedCompany",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedCompany",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "selectedCompany.name"
                  }
                ],
                "type": "Company",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompanySearchDropdownAcoreUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalId",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils__companies",
        "kind": "LinkedHandle",
        "name": "companies"
      },
      {
        "condition": "hasSelectedCompany",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedCompany",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Company",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f592adc2904f8706671fb8fd9feefc1d",
    "id": null,
    "metadata": {},
    "name": "CompanySearchDropdownAcoreUtilsQuery",
    "operationKind": "query",
    "text": "query CompanySearchDropdownAcoreUtilsQuery(\n  $companyId: ID!\n  $hasSelectedCompany: Boolean!\n  $filter: CompanySearchFilter\n  $count: Int!\n  $cursor: String\n) {\n  ...CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query\n  selectedCompany: node(id: $companyId) @include(if: $hasSelectedCompany) {\n    __typename\n    id\n    ... on Company {\n      name\n    }\n  }\n}\n\nfragment CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query on Query {\n  companies(first: $count, after: $cursor, filter: $filter) {\n    edges {\n      node {\n        id\n        internalId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90bec2b252492a3e20246388a2190ffb";

export default node;
