/**
 * @generated SignedSource<<9b4e140d1de26ec1c3a18f735b94a79e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ToastType = "error" | "success" | "%future added value";
export type AcoreUtilsToastsQuery$variables = {};
export type AcoreUtilsToastsQuery$data = {
  readonly __typename: string;
  readonly site: {
    readonly toasts: ReadonlyArray<{
      readonly text: string;
      readonly title: string | null;
      readonly type: ToastType | null;
    }>;
  };
};
export type AcoreUtilsToastsQuery = {
  response: AcoreUtilsToastsQuery$data;
  variables: AcoreUtilsToastsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Toast",
            "kind": "LinkedField",
            "name": "toasts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AcoreUtilsToastsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AcoreUtilsToastsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "52a2723baa37bb0d6453d136e4aedf38",
    "id": null,
    "metadata": {},
    "name": "AcoreUtilsToastsQuery",
    "operationKind": "query",
    "text": "query AcoreUtilsToastsQuery {\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "15d66162a6c7b4171f2a70adce15860b";

export default node;
