import { Company as GQLCompany } from '@attentive/data/types';

interface Company<T extends string | number> {
  id: T;
  name: GQLCompany['name'];
}

interface SelectedCompany<T extends string | number> {
  id: T;
  name?: GQLCompany['name'];
}

export function buildCompaniesOptions<T extends number | string>(
  companies: Array<Company<T> | null>,
  selectedCompany: SelectedCompany<T> | null
) {
  const validCompanies = companies.filter(Boolean) as Array<Company<T>>;

  // check to see if the selected company is already in the list — if it isn't add it
  if (selectedCompany && !validCompanies.find(({ id }) => id === selectedCompany.id)) {
    const { id, name } = selectedCompany;
    if (name) {
      validCompanies.push({ id, name });
    }
  }

  validCompanies.sort((a, b) => a.name.localeCompare(b.name));

  return (
    validCompanies.map((company) => {
      return {
        value: company.id || '',
        label: company.name || '',
      };
    }) || []
  );
}
