// NOTE: This is the allowlist of public interface exported from @attentive/acore-utils.
// Do not make a breaking change here!

export { isCompanyInUk } from './user';
export { useIsCompanyInUk, useDebouncedValue, useLocaleConfig } from './hooks';
export { loadCSS } from './css';
export { MFE_NAME_ATTRIBUTE, getMfeNameProps, useMfeNameProps } from './testUtils';
export {
  useShouldDisablePromoModals,
  LOCAL_STORAGE_DISABLE_PROMO_MODALS_KEY,
} from './hooks/useShouldDisablePromoModals';
export { encodeExternalId } from './encodeExternalId';
