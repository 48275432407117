import React from 'react';
import { ConcreteRequest, OperationType, VariablesOf } from 'relay-runtime';

import { MenuItems } from '@attentive/picnic';

import { SearchableDropdown } from './SearchableDropdown';

import { CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$data } from './__generated__/CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query.graphql';

export interface QueryToItemsFn<T extends OperationType> {
  (
    companies: CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query$data,
    queryData: T['response']
  ): MenuItems;
}

export interface WrappedSearchableDropdown<T extends OperationType>
  extends Omit<
    React.ComponentProps<typeof SearchableDropdown>,
    'gqlQuery' | 'queryToItemsFn' | 'filter'
  > {
  vars?: Omit<
    VariablesOf<T>,
    'query' | 'count' | 'companyId' | 'internalIds' | 'hasSelectedCompany'
  >;
  filterVars: Pick<VariablesOf<T>, 'filter'>['filter'];
}

export const createSearchableDropdown = <T extends OperationType>(
  query: ConcreteRequest,
  queryToItemsFn: QueryToItemsFn<T>
) => {
  const WrappedSearchableDropdown: React.VFC<WrappedSearchableDropdown<T>> = ({
    vars,
    filterVars,
    ...rest
  }) => {
    return (
      <SearchableDropdown
        vars={vars}
        filterVars={filterVars}
        gqlQuery={query}
        queryToItemsFn={queryToItemsFn}
        {...rest}
      />
    );
  };

  WrappedSearchableDropdown.displayName = 'SearchableDropdown';

  return WrappedSearchableDropdown;
};
