import { useLazyLoadQuery } from 'react-relay';
import AcoreUtilsToastsQuery, {
  AcoreUtilsToastsQuery as AcoreUtilsToastsQueryType,
} from './__generated__/AcoreUtilsToastsQuery.graphql';

const useQueuedToasts = () => {
  const queryData = useLazyLoadQuery<AcoreUtilsToastsQueryType>(AcoreUtilsToastsQuery, {});

  return queryData.site.toasts;
};

export { useQueuedToasts };
