/**
 * Utility to encode the companies external id
 * @param externalID
 */
export function encodeExternalId(externalID: number): string {
  const BASE64 = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '-',
    '_',
  ];
  let str = '';
  for (let i = 0; i < 3; i++) {
    const index = (Number(externalID) / Math.pow(64, 3 - 1 - i)) % 64;
    str += BASE64[Math.floor(index)];
  }

  return str;
}
