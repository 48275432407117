/**
 * @generated SignedSource<<144260b5a4d248cf99a1ebb9efc1af22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanySearchFilter = {
  includeReadonly?: boolean | null;
  searchTerm?: string | null;
};
export type LegacyCompanySearchDropdownAcoreUtilsQuery$variables = {
  count: number;
  cursor?: string | null;
  filter: CompanySearchFilter;
  hasSelectedCompany: boolean;
  internalIds: ReadonlyArray<number>;
};
export type LegacyCompanySearchDropdownAcoreUtilsQuery$data = {
  readonly selectedCompany?: {
    readonly companies: ReadonlyArray<{
      readonly internalId: number;
      readonly name: string;
    } | null>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query">;
};
export type LegacyCompanySearchDropdownAcoreUtilsQuery = {
  response: LegacyCompanySearchDropdownAcoreUtilsQuery$data;
  variables: LegacyCompanySearchDropdownAcoreUtilsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSelectedCompany"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "internalIds"
},
v5 = [
  {
    "kind": "Variable",
    "name": "internalIds",
    "variableName": "internalIds"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LegacyCompanySearchDropdownAcoreUtilsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query"
      },
      {
        "condition": "hasSelectedCompany",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedCompany",
            "args": (v5/*: any*/),
            "concreteType": "GetCompaniesByInternalIdResponse",
            "kind": "LinkedField",
            "name": "companiesByInternalId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "companies",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LegacyCompanySearchDropdownAcoreUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils__companies",
        "kind": "LinkedHandle",
        "name": "companies"
      },
      {
        "condition": "hasSelectedCompany",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedCompany",
            "args": (v5/*: any*/),
            "concreteType": "GetCompaniesByInternalIdResponse",
            "kind": "LinkedField",
            "name": "companiesByInternalId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "companies",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b74f334c67d599948176f30080163df0",
    "id": null,
    "metadata": {},
    "name": "LegacyCompanySearchDropdownAcoreUtilsQuery",
    "operationKind": "query",
    "text": "query LegacyCompanySearchDropdownAcoreUtilsQuery(\n  $internalIds: [Long!]!\n  $hasSelectedCompany: Boolean!\n  $filter: CompanySearchFilter!\n  $count: Int!\n  $cursor: String\n) {\n  ...CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query\n  selectedCompany: companiesByInternalId(internalIds: $internalIds) @include(if: $hasSelectedCompany) {\n    companies {\n      internalId\n      name\n      id\n    }\n  }\n}\n\nfragment CompanySearchDropdownCompaniesRefetchableFragmentAcoreUtils_query on Query {\n  companies(first: $count, after: $cursor, filter: $filter) {\n    edges {\n      node {\n        id\n        internalId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8437e3693ec33601c529ec12c37dddd3";

export default node;
