import { StandardMutationError } from '../Logger';

type DefaultErrorFailure = {
  readonly __typename: 'DefaultErrorFailure';
  readonly message: string;
  readonly title: string;
  readonly status: string;
  readonly traceId?: string | null;
};

type MutationResponse<S extends String> =
  | DefaultErrorFailure
  | { readonly __typename: '%other' }
  | { readonly __typename: S }
  | null;

type MutationSuccess<T extends MutationResponse<S>, S extends String> = NonNullable<
  Exclude<T, DefaultErrorFailure | { readonly __typename: '%other' }>
>;

export const handleMutationResponse = <T extends MutationResponse<S>, S extends String>({
  response,
  onSuccess,
  onError,
}: {
  response: T;
  onSuccess?: (data: MutationSuccess<T, S>) => void;
  onError?: (e: Error) => void;
}): void => {
  if (response === null) {
    onError?.(new Error('Mutation response is null'));
  } else if (response.__typename === '%other') {
    onError?.(new Error('Mutation response is %other'));
  } else if (response.__typename === 'DefaultErrorFailure') {
    const defaultErrorFailure = response as DefaultErrorFailure;
    console.error('traceId', defaultErrorFailure.traceId);
    onError?.(
      new StandardMutationError(defaultErrorFailure.message, {
        title: defaultErrorFailure.title,
        message: defaultErrorFailure.message,
        status: defaultErrorFailure.status,
        traceId: defaultErrorFailure.traceId,
      })
    );
  } else {
    onSuccess?.(response as MutationSuccess<T, S>);
  }
};
