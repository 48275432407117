import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigPT: LocaleConfig = {
  locale: Locale.ptPT,
  currencyConfig: {
    currencyCode: CurrencyCode.PT,
  },
  optOutLanguageRequired: true,
};
