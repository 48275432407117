import { useEffect, useState } from 'react';

export const LOCAL_STORAGE_DISABLE_PROMO_MODALS_KEY = 'DISABLE_PROMO_MODALS';

function getValue() {
  return window.localStorage.getItem(LOCAL_STORAGE_DISABLE_PROMO_MODALS_KEY) === 'true';
}

function useShouldDisablePromoModals() {
  const [shouldDisablePromoModals, setShouldDisablePromoModals] = useState(getValue());

  useEffect(() => {
    const update = () => {
      setShouldDisablePromoModals(getValue());
    };

    window.addEventListener('storage', update);

    return () => {
      window.removeEventListener('storage', update);
    };
  }, []);

  return shouldDisablePromoModals;
}

export { useShouldDisablePromoModals };
