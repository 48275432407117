import { AuthUserCompany } from '@attentive/data';

/**
 * Returns true if the ISO country code of a company is GB (a.k.a. UK)
 * @deprecated: use `useIsCompanyInUk` hook instead
 *
 * @public
 */
export function isCompanyInUk(company: Pick<AuthUserCompany, 'country'>): boolean {
  return company.country === 'GB';
}
