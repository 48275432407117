import React from 'react';
import { Button } from '@attentive/picnic';
import { ChatStatusIndicator } from './styledComponents';
import { IndicatorProps } from './types';

export const ChatIndicatorButton = ({
  onClick,
  active,
  unreadCount: _unreadCount,
  ...rest
}: IndicatorProps) => {
  return (
    <Button
      variant="basic"
      size="medium"
      onClick={onClick}
      disabled={!active}
      data-nav-id="live-chat-button-btn"
      {...rest}
    >
      <ChatStatusIndicator active={active} css={{ mr: '$space2' }} />
      Live Chat
    </Button>
  );
};
