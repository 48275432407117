import React from 'react';

import { Box, Text } from '@attentive/picnic';
import {
  GraphqlIncompleteDataError,
  GraphqlPermissionDeniedError,
  GraphqlRequestFailureError,
  GraphqlScalarDeserializationError,
  GRAPHQL_REQUEST_FAILURE_ERROR_CODE,
  GRAPHQL_PERMISSION_DENIED_ERROR_CODE,
  GRAPHQL_INCOMPLETE_DATA_ERROR_CODE,
  GRAPHQL_SCALAR_DESERIALIZATION_ERROR_CODE,
} from '@attentive/data';

export const GraphqlRequestFailureErrorMessage: React.VFC<{
  error: GraphqlRequestFailureError;
}> = ({ error }) => {
  return (
    <Box>
      <Text>
        A network request to {error.url} failed. [{GRAPHQL_REQUEST_FAILURE_ERROR_CODE}]
      </Text>
      <Text>{error.message}</Text>
    </Box>
  );
};

export const GraphqlPermissionDeniedErrorMessage: React.VFC<{
  error: GraphqlPermissionDeniedError;
}> = ({ error }) => {
  return (
    <Box>
      <Text>
        A permission denied error was encountered. A request to access Graphql data
        {error.path && error.path.length > 0 ? ` at the path "${error.path.join('.')}"` : ''} was
        denied. [{GRAPHQL_PERMISSION_DENIED_ERROR_CODE}]
      </Text>
      <Text>{error.message}</Text>
    </Box>
  );
};

export const GraphqlIncompleteDataErrorMessage: React.VFC<{
  error: GraphqlIncompleteDataError;
}> = ({ error }) => {
  return (
    <Box>
      <Text>
        A error was encountered. The response had unexpected incomplete data. [
        {GRAPHQL_INCOMPLETE_DATA_ERROR_CODE}]
      </Text>
      <Text>{error.message}</Text>
    </Box>
  );
};

export const GraphqlScalarDeserializationErrorMessage: React.VFC<{
  error: GraphqlScalarDeserializationError;
}> = ({ error }) => {
  return (
    <Box>
      <Text>
        A error was encountered. Unable to deserialize {error.scalarType} scalar. [
        {GRAPHQL_SCALAR_DESERIALIZATION_ERROR_CODE}]
      </Text>
      <Text>{error.message}</Text>
    </Box>
  );
};
