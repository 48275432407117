import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigNL: LocaleConfig = {
  locale: Locale.nlNL,
  currencyConfig: {
    currencyCode: CurrencyCode.NL,
  },
  optOutLanguageRequired: true,
};
