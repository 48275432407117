import { atom } from 'jotai';
import {
  Role,
  Scope,
  Permission,
  Rule,
  RoleRule,
  ScopeRule,
  permissionRules,
} from '@attentive/data';

const isRoleRule = (rule: Rule): rule is RoleRule => 'roles' in rule;
const satisfiesRoleRule = (rule: RoleRule, roles: Set<Role>) =>
  rule.roles.some((r) => roles.has(r));
const satisfiesScopeRule = (rule: ScopeRule, scopes: Set<Scope>) =>
  rule.scopes.some((s) => scopes.has(s));

const satisfiesRule = (rule: Rule, roles: Set<Role>, scopes: Set<Scope>): boolean => {
  if (isRoleRule(rule)) {
    return satisfiesRoleRule(rule, roles);
  }
  return satisfiesScopeRule(rule, scopes);
};

/**
 * Returns a hash of all permissions, and whether this set of roles grants each
 */
const computePermissions = (roles: Set<Role>, scopes: Set<Scope>): Record<Permission, boolean> => {
  const permissions = Object.entries(permissionRules).map(([permission, rule]) => {
    const satisfies = satisfiesRule(rule, roles, scopes);
    return [permission, satisfies] as const;
  });
  return Object.fromEntries(permissions) as Record<Permission, boolean>;
};

export const rolesRWAtom = atom<Role[]>([]);
export const scopesRWAtom = atom<Scope[]>([]);

export const rolesAtom = atom((get) => new Set(get(rolesRWAtom)));
export const scopesAtom = atom((get) => new Set(get(scopesRWAtom)));
export const permissionsAtom = atom((get) => {
  const roles = get(rolesAtom);
  const scopes = get(scopesAtom);
  return computePermissions(roles, scopes);
});
