import React, { ReactNode, Component } from 'react';

import { Box, Heading, Text, Banner } from '@attentive/picnic';

import { ErrorDetailsAccordion } from './ErrorDetailsAccordion';

interface State {
  error?: Error | null;
}

interface Props {
  children: ReactNode;
  onError?: (error: Error) => void;
}

const shouldShowSafariRegexWarning = (error: Error) =>
  // @ts-ignore
  window.safari && error?.message.toLocaleLowerCase().includes('invalid regular expression');

// NOTE: This is only for MFEs top level error boundary. If you want to use an error boundary
// anywhere else in your app use:
// import { ErrorBoundary } from 'react-error-boundary';
export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      const showSafariRegexWarning = shouldShowSafariRegexWarning(error);

      return (
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '$space4',
          }}
        >
          <Text css={{ mb: '$space4', mt: '$space10', textAlign: 'center' }}>
            Unfortunately, you've encountered an error. Please contact your Attentive representative
            or reach out to whiteglove@attentivemobile.com if this problem persists.
          </Text>
          {showSafariRegexWarning && (
            <Banner variant="warning">
              <Heading variant="lg">Safari Error</Heading>
              <Text>This feature does not work in Safari. Please try using another browser.</Text>
            </Banner>
          )}
          <ErrorDetailsAccordion error={error} />
        </Box>
      );
    }

    return this.props.children;
  }
}
