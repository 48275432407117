// NOTE: This is the allowlist of public interface exported from @attentive/acore-utils.
// Do not make a breaking change here!

export {
  initializeUserLeap,
  updateTrackedUser,
  identify,
  track,
  reset,
  trackPageView,
} from './tracker';
export type { DefaultTrackProperties } from './tracker';
