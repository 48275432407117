import {
  ChatStatusIndicator,
  InlineUnreadChatBadge,
  LiveChat,
  ZendeskPopoverContent,
} from './styledComponents';
import { Icon, PicnicCss, Popover, VisuallyHidden } from '@attentive/picnic';
import React from 'react';
import { IndicatorProps } from './types';

const indicatorCss: PicnicCss = {
  position: 'absolute',
  top: '$space3',
  left: '$space4',
  zIndex: '$zIndex5',
};

const badgeCss: PicnicCss = {
  position: 'absolute',
  top: '$space3',
  left: '$space4',
  zIndex: '$zIndex5',
};

export const ChatIndicatorIcon = ({ onClick, unreadCount, active, ...rest }: IndicatorProps) => {
  const [showPopover, setShowPopover] = React.useState(false);

  return (
    <Popover open={showPopover}>
      <Popover.Trigger>
        <LiveChat
          onClick={onClick}
          onMouseOver={() => setShowPopover(true)}
          onMouseOut={() => setShowPopover(false)}
          {...rest}
        >
          <Icon name="MessageBubbleDots" />
          <VisuallyHidden>Live chat</VisuallyHidden>
          {!active || unreadCount === 0 ? (
            <ChatStatusIndicator active={active} css={indicatorCss} />
          ) : (
            <InlineUnreadChatBadge unreadCount={unreadCount} css={badgeCss} />
          )}
        </LiveChat>
      </Popover.Trigger>
      <ZendeskPopoverContent />
    </Popover>
  );
};
