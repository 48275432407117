import React, { useState, useRef, Suspense, ReactNode, useEffect } from 'react';
import { useCombobox } from 'downshift';
import { LoadMoreFn, useQueryLoader } from 'react-relay';
import { ConcreteRequest, OperationType, VariablesOf } from 'relay-runtime';
import { isEqual } from '@attentive/nodash';

import {
  Box,
  LoadingIndicator,
  InputGroup,
  Button,
  Icon,
  TextInput,
  MenuItem,
  MenuItems,
  MenuItemValueType,
  SelectProps,
  ArrowWrapper,
  SelectPopout,
  IconButton,
} from '@attentive/picnic';

import { CompanySearchLoader, CompanySearchLoaderOnLoadFn } from './CompanySearchLoader';
import { QueryToItemsFn } from './create-searchable-dropdown';
import { CompanyNames } from './CompanyNames';

const emptyFn = () => {};

const itemToString = (item: MenuItem | null) => item?.label ?? '';

const ComponentSearchLoading: React.FC<{ onLoading: () => void }> = ({ onLoading }) => {
  useEffect(() => {
    onLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LoadingIndicator css={{ color: 'inherit' }} />;
};

export interface SearchableDropdownProps<T extends OperationType>
  extends Omit<SelectProps, 'onError' | 'onChange' | 'value'> {
  gqlQuery: ConcreteRequest;
  vars?: VariablesOf<T>;
  filterVars: Pick<VariablesOf<T>, 'filter'>['filter'];
  fallback?: ReactNode;
  count?: number;
  value?: MenuItemValueType | null;
  queryToItemsFn: QueryToItemsFn<T>;
  clearButton?: boolean;
  onError?: (err: Error) => void;
  onChange:
    | ((value: MenuItemValueType | null, label: string | null) => void)
    | ((value: MenuItem | null) => void)
    | ((value: string | null) => void)
    | ((value: number | null) => void);
}

// XXX: We needed to make a copy of SearchableSelect since the Picnic version only supports direct
// children (SearchableSelect.Item) and for GQL we need to mount a child component wrapped with a
// <Suspense>.
export const SearchableDropdown = <T extends OperationType>({
  gqlQuery,
  vars,
  filterVars,
  placeholder = 'Search',
  css = {},
  value,
  count = 20,
  onChange,
  size = 'medium',
  state = 'normal',
  disabled = false,
  queryToItemsFn,
  clearButton,
  ...rest
}: SearchableDropdownProps<T>) => {
  const [term, setTerm] = useState('');
  const [items, setItems] = useState<MenuItems>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<LoadMoreFn<T> | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [queryReference, loadQuery] = useQueryLoader<T>(gqlQuery, null);

  const itemsLookup = items.reduce(
    (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
      prev[curr.value] = {
        item: curr,
        index,
      };
      return prev;
    },
    {}
  );

  React.useEffect(() => {
    loadQuery({
      filter: {
        ...filterVars,
        searchTerm: term,
      },
      hasSelectedCompany: value != null,
      count,
      companyId: value || '',
      internalIds: value ? [value] : [],
      ...vars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, loadQuery, count, value, filterVars]);

  const handleChange = (onChange || emptyFn) as (
    value: MenuItemValueType | null,
    label: string | null
  ) => void;

  const getItem = (itemValue?: MenuItemValueType | null) => {
    if (itemValue != null) {
      return items.find((item) => item.value === itemValue && !item.disabled) ?? null;
    }

    return null;
  };

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getToggleButtonProps,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString,
    inputValue: term,
    items,
    selectedItem: getItem(value),

    onSelectedItemChange: ({ selectedItem: changedSelectedItem }) => {
      if (changedSelectedItem) {
        setTerm('');

        const val = changedSelectedItem.value as string;
        const label = changedSelectedItem.label;
        handleChange(val, label);
      }
    },
  });

  const handleItemsChanged: CompanySearchLoaderOnLoadFn<T> = (
    paginationData,
    queryData,
    hasNext,
    loadNext
  ) => {
    setLoading(false);
    loadMoreRef.current = loadNext;
    setHasNextPage(hasNext);

    const newItems = queryToItemsFn(paginationData, queryData);

    if (!isEqual(newItems, items)) {
      setItems(newItems);
    }
  };

  const handleLoadMore = () => {
    if (loadMoreRef.current) {
      loadMoreRef.current(count);
    }
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.currentTarget.value);
    setItems([]);
    openMenu();
  };

  const handleClearInput = () => {
    setTerm('');
    setItems([]);
    handleChange(null, null);
  };

  return (
    <Box ref={wrapperRef} css={{ width: '100%', position: 'relative', ...css }}>
      <Box {...getComboboxProps()}>
        <InputGroup>
          <TextInput
            value={term}
            placeholder={selectedItem ? selectedItem.label : placeholder}
            {...getInputProps()}
            onClick={openMenu}
            onChange={handleSearchTermChange}
            size={size === 'medium' ? 'normal' : 'small'}
            state={state}
            css={{
              width: '100%',
              '&::placeholder': { color: selectedItem ? '$textDefault' : '$textSubdued' },
              ...css,
            }}
            disabled={disabled}
            {...rest}
          />
          <InputGroup.RightElement>
            <Suspense fallback={<ComponentSearchLoading onLoading={() => setLoading(true)} />}>
              {clearButton && (term || value) && (
                <IconButton
                  variant="subdued"
                  disabled={disabled}
                  iconName="X"
                  description="Close"
                  size="extraSmall"
                  css={{ mr: '$space2', ml: '$space3' }}
                  onClick={handleClearInput}
                />
              )}
              {queryReference && (
                <CompanySearchLoader
                  gqlQuery={gqlQuery}
                  queryReference={queryReference}
                  onLoad={handleItemsChanged}
                  onLoadingNext={() => setLoading(true)}
                />
              )}
            </Suspense>
            <Button
              variant="subdued"
              disabled={disabled}
              {...getToggleButtonProps()}
              css={{ minHeight: 12, p: '$space2' }}
            >
              <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
                <Icon
                  css={{ display: 'block' }}
                  name="ChevronDown"
                  size="small"
                  description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
                />
              </ArrowWrapper>
            </Button>
          </InputGroup.RightElement>
        </InputGroup>
      </Box>
      <Box {...getMenuProps()} css={{ outline: 'none' }}>
        {isOpen && (
          <SelectPopout>
            <CompanyNames
              loading={loading}
              items={items}
              highlightedIndex={highlightedIndex}
              selectedItem={selectedItem as MenuItem}
              itemsLookup={itemsLookup}
              size={size}
              getItemProps={getItemProps}
            />
            {hasNextPage && (
              <Button
                variant="primary"
                size="small"
                loading={loading}
                onClick={handleLoadMore}
                css={{ display: 'block', margin: '$space4 auto 0 auto' }}
              >
                Load more
              </Button>
            )}
          </SelectPopout>
        )}
      </Box>
    </Box>
  );
};
