import React from 'react';
import { useProjectName } from '../Logger/ProjectNameContext';
import { useLibLogger, LibError } from '../Logger';
import { LibNames } from '../types';

interface ErrorBoundaryProps {
  fallback?: React.ReactNode;
  children: React.ReactNode;
  onError: (error: Error) => void;
}

interface State {
  error?: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
    this.props.onError(error);
  }

  render() {
    const { fallback = null, children } = this.props;
    const { error } = this.state;

    return error ? fallback : children;
  }
}

interface LibErrorBoundaryProps {
  libName: LibNames;
  /**
   * When supplied the component will catch the error and display the fallback.
   * Otherwise it rethrows the error.
   */
  fallback?: React.ReactNode;
  children: React.ReactNode;
  onError?: (error: Error, projectName: string, libName: LibNames) => void;
}

const LibErrorBoundary: React.FC<React.PropsWithChildren<LibErrorBoundaryProps>> = ({
  libName,
  fallback,
  children,
  onError,
}) => {
  const projectName = useProjectName();
  const logger = useLibLogger(libName);

  const handleOnError = (err: Error) => {
    const libError = err instanceof LibError ? err : new LibError(err, libName);
    // Preserve the original error's stack
    libError.stack = err.stack;

    logger.logError(libError);
    onError?.(libError, projectName, libName);

    // We don't want this error boundary to handle errors. It's goal is to
    // tag uncaught errors with the lib they originate from. Consumers in
    // apps/mfes are responsible for actually handling the errors.
    if (!fallback) {
      throw libError;
    }
  };

  return (
    <ErrorBoundary fallback={fallback} onError={handleOnError}>
      {children}
    </ErrorBoundary>
  );
};

export { LibErrorBoundary };
