import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigBR: LocaleConfig = {
  locale: Locale.ptBR,
  currencyConfig: {
    currencyCode: CurrencyCode.BR,
  },
  optOutLanguageRequired: false,
};
