import { CountryCode, SupportedRegion } from '@attentive/locale-utils';
import { useRegionalizedAttentiveDomainSuffix } from './useRegionalizedAttentiveDomainSuffix';

export const useCompanyShortName = (
  attentiveDomain?: string,
  country: SupportedRegion | string = CountryCode.US
): string => {
  const regionalizedAttentiveDomainSuffix = useRegionalizedAttentiveDomainSuffix(country);

  return attentiveDomain?.replace(regionalizedAttentiveDomainSuffix, '') || '';
};
