import { EventBus, PublishTypes } from '../EventBus';

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Syncing = 'syncing',
}

/**
 * Options for toast component.
 */
export interface ToastOptions {
  type?: ToastType;
  title?: string;
  text: string;
}

/**
 * Toast static class.
 *
 * The `Toast` singleton class allows the creation of "toast" notifications on the platform. This will
 * only work within apps.
 *
 * @deprecated use the useToast() hook instead
 * @static
 */
export abstract class Toast {
  /**
   * Creates a toast message.
   *
   * ```
   * {
   *    type?: ToastType.Success | ToastType.Error;
   *    title?: string;
   *    text: string
   * }
   * ```
   *
   * @param { Object } options
   *
   * @returns `Promise`
   * @static
   * @deprecated use the useToast() hook instead
   * @public
   */
  public static async create(options: ToastOptions) {
    // ToastProvider currently exists in client-ui here: /apps/client-ui/src/components/ToastContainer.tsx
    // We should consider moving it into the ACore Library
    EventBus.publish(PublishTypes.CreateToast, options);
  }
}
