import { TrackerEventTypes } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { Config } from '../Config';
import { logDatadogError, ProjectNames } from '../Logger';
import { Role } from '@attentive/data';
import { datadogRum } from '@datadog/browser-rum';

type PendoConfig = {
  visitor: {
    id: string;
    [index: string]: string | boolean | number;
  };
  account: {
    databaseId: number;
    [index: string]: string | boolean | number;
  };
};

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    gtag?: any;
    heap: any;
    FS: any;
    UserLeap: any;
    pendo?: {
      initialize(config: PendoConfig): void;
      track(event: string, properties?: Record<string, unknown>): void;
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

// This definition came from https://developers.heap.io/reference#track which is the best place I could find them
/* eslint-disable @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any */
interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}
/* eslint-enable @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any */

declare var heap: Heap; // eslint-disable-line no-var

// We don't necessarily know what Fullstory types will be
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type fullStoryAny = any;

interface Fullstory {
  identify(uid: string, userVars?: Record<string, fullStoryAny>): void;
  anonymize(): void;
  event(event: string, properties?: Record<string, fullStoryAny>): void;
  setUserVars(userVars: Record<string, fullStoryAny>): void;
  getCurrentSessionURL(): string;
  log(msg: string): void;
  log(level: string, msg: string): void;
  shutdown(): void;
  restart(): void;
  consent(consent: boolean): void;
}

declare var FS: Fullstory; // eslint-disable-line no-var

export interface DefaultTrackProperties {
  companyId?: number;
  companyName?: string;
  companyCountry?: string;
  eventId: string;
  superUserView: boolean;

  csTier?: string;
  goLiveDate?: string;
  vertical?: string;
  launchStatus?: string;
  lostType?: string;
  freeTrialEndDate?: string;
  inFreeTrial?: boolean;
  isLive?: boolean;
  isAccountSuspended?: boolean;
  upcomingRenewalDate?: string;
  managingAgencyId?: string;
  managingAgencyName?: string;
}

interface TrackedUser {
  internalId: number;
  email: string;
  roles?: string[];
  company: {
    internalId: number;
    name: string;
    country: string;
    csTier?: string;
    goLiveDate?: string;
    companyType?: string;
    vertical?: string;
    launchStatus?: string;
    lostType?: string;
    freeTrialEndDate?: string;
    inFreeTrial?: boolean;
    isLive?: boolean;
    isAccountSuspended?: boolean;
    upcomingRenewalDate?: string;
    managingAgencyId?: string;
    managingAgencyName?: string;
  };
}

let trackedUser: TrackedUser | null = null;

export const updateTrackedUser = (user: TrackedUser | null) => {
  trackedUser = user;
};

/**
 * UserLeap begins to send "no-code" events (defined in their dashboard) as soon as the snippet loads,
 * so we need access to initialize it outside of the main identify flow to ensure these events are
 * associated with the correct userId.
 */
export const initializeUserLeap = (userId: number) => {
  try {
    if (window.UserLeap) {
      window.UserLeap('setUserId', userId.toString());
    }
  } catch (err) {
    console.warn('Could not setUserId for UserLeap', err);
  }
};

export const trackPageView = (pageURL: string) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return track({ eventName: 'page_view', properties: { page_path: pageURL } });
};

function hasSuperUserRole(roles?: string[]) {
  return roles?.includes(Role.RoleSuperUser.toString());
}

export const identify = (projectName: ProjectNames, currentUser: TrackedUser) => {
  updateTrackedUser(currentUser);
  const { internalId: id, email, roles } = currentUser;

  const userProperties = {
    superUserView: hasSuperUserRole(roles),
    // GA and heap only support KV pairs where the value is a number or string
    roles: roles?.join(' '),
  };

  try {
    if (window.gtag) {
      gtag('config', Config.get('googleAnalyticsId'), {
        user_id: id,
        send_page_view: false,
        custom_map: {
          dimension1: 'companyName',
          dimension3: 'roles',
        },
        // https://support.google.com/analytics/thread/4879836?hl=en explains how to pass this w/ gtag and each page view
        ...userProperties,
      });
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not identify user in GA' } });
  }

  try {
    if (window.heap) {
      const selectedCompanyType = currentUser.company.companyType;

      heap.identify(id.toString());
      heap.addUserProperties({ ...userProperties, selectedCompanyType });
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not identify user in Heap' } });
  }

  try {
    if (window.FS) {
      FS.identify(id.toString(), userProperties);
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not identify user in F5' } });
  }

  try {
    if (window.UserLeap) {
      window.UserLeap('setUserId', id.toString());
      window.UserLeap('setEmail', email);
      window.UserLeap('setAttributes', userProperties);
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not identify user in UserLeap' } });
  }

  return trackPageView(window.location.pathname);
};

export const reset = (projectName: ProjectNames) => {
  updateTrackedUser(null);

  try {
    if (window.gtag) {
      gtag('config', Config.get('googleAnalyticsId'), { send_page_view: false });
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not reset tracking data in GA' } });
  }

  try {
    if (window.heap) {
      heap.resetIdentity();
    }
  } catch (err) {
    logDatadogError(projectName, err, {
      error: { title: 'Could not reset tracking data in Heap' },
    });
  }

  try {
    if (window.FS) {
      FS.anonymize();
    }
  } catch (err) {
    logDatadogError(projectName, err, { error: { title: 'Could not reset tracking data in F5' } });
  }

  try {
    if (window.UserLeap) {
      window.UserLeap.logoutUser();
    }
  } catch (err) {
    logDatadogError(projectName, err, {
      error: { title: 'Could not reset tracking data in UserLeap' },
    });
  }

  return trackPageView(window.location.pathname);
};

export const track = ({ eventName, properties }: TrackerEventTypes) => {
  const defaults: DefaultTrackProperties = {
    eventId: uuidv4(),
    companyId: trackedUser?.company.internalId,
    companyName: trackedUser?.company.name,
    companyCountry: trackedUser?.company.country,
    csTier: trackedUser?.company.csTier,
    goLiveDate: trackedUser?.company.goLiveDate,
    vertical: trackedUser?.company.vertical,
    launchStatus: trackedUser?.company.launchStatus,
    lostType: trackedUser?.company.lostType,
    freeTrialEndDate: trackedUser?.company.freeTrialEndDate,
    inFreeTrial: trackedUser?.company.inFreeTrial,
    isLive: trackedUser?.company.isLive,
    isAccountSuspended: trackedUser?.company.isAccountSuspended,
    upcomingRenewalDate: trackedUser?.company.upcomingRenewalDate,
    managingAgencyId: trackedUser?.company.managingAgencyId,
    managingAgencyName: trackedUser?.company.managingAgencyName,

    superUserView: hasSuperUserRole(trackedUser?.roles) || false,
  };

  const trackerProperties = {
    ...properties,
    ...defaults,
  };

  try {
    if (window.gtag) {
      gtag('event', eventName, trackerProperties);
    }
    if (window.pendo) {
      window.pendo.track(eventName, trackerProperties);
    }
  } catch (err) {
    console.warn('Could not send event to Google Analytics', err);
  }

  try {
    if (window.heap) {
      const { eventId: _eventId, ...heapTrackerProperties } = trackerProperties;
      heap.track(eventName, heapTrackerProperties);
    }
  } catch (err) {
    console.warn('Could not send event to Heap', err);
  }
  try {
    if (window.FS) {
      FS.event(eventName, trackerProperties);
    }
  } catch (err) {
    console.warn('Could not send event to F5', err);
  }

  try {
    // Only track UserLeap events when a user is authenticated
    if (window.UserLeap && trackedUser) {
      window.UserLeap('track', eventName);
    }
  } catch (err) {
    console.warn('Could not send event to UserLeap', err);
  }

  datadogRum.addAction(eventName, properties);
};
