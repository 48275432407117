import React from 'react';
import { UseComboboxGetItemPropsOptions } from 'downshift';

import {
  Text,
  List,
  Box,
  SearchableSelect as PicnicSearchableSelect,
  getItemPropsType,
  MenuItem,
  MenuItems,
  MenuItemValueType,
  SelectItemsList,
} from '@attentive/picnic';

type ItemsLookup = Record<MenuItemValueType, { item: MenuItem; index: number }>;

interface CompanyNamesProps {
  loading?: boolean;
  items: MenuItems;
  highlightedIndex: number;
  selectedItem: MenuItem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemsLookup: ItemsLookup;
  size: 'medium' | 'small';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (options: UseComboboxGetItemPropsOptions<MenuItem>) => any;
}

export const CompanyNames = ({
  loading = false,
  items,
  highlightedIndex,
  selectedItem,
  itemsLookup,
  size,
  getItemProps,
}: CompanyNamesProps) => {
  if (items.length === 0) {
    if (loading) {
      return (
        <Box css={{ p: '$space2' }}>
          <Text>Loading</Text>
        </Box>
      );
    }
    return (
      <Box css={{ p: '$space2' }}>
        <Text>No results</Text>
      </Box>
    );
  }

  return (
    <List variant="unstyled">
      <SelectItemsList
        highlightedIndex={highlightedIndex as number}
        selectedItem={selectedItem as MenuItem}
        getItemProps={getItemProps as getItemPropsType}
        itemsLookup={itemsLookup as ItemsLookup}
        size={size}
      >
        {items.map(({ value, label }) => {
          return (
            <PicnicSearchableSelect.Item key={value} value={value}>
              {label}
            </PicnicSearchableSelect.Item>
          );
        })}
      </SelectItemsList>
    </List>
  );
};
