import { useAtom, useAtomValue } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { useCallback } from 'react';
import { readInlineData } from 'react-relay';

import { ExtractNodeType } from '@attentive/data';

import { AppStore } from '../atoms';
import { ClientUIFeatureFlagsFragment } from './ClientUIFeatureFlags';
import {
  ClientUIFeatureFlagsFragment_query$data,
  ClientUIFeatureFlagsFragment_query$key,
} from './__generated__/ClientUIFeatureFlagsFragment_query.graphql';

type Defined<T, K extends keyof T = keyof T> = {
  [P in K]-?: Exclude<T[P], undefined | null>;
};

type ClientUICompanyFeatureFlags = Omit<
  Defined<ExtractNodeType<ClientUIFeatureFlagsFragment_query$data['company']>>,
  ' $fragmentRefs' | ' $refType' | '__typename'
>;

export type CompanyFeatureFlags = Partial<ClientUICompanyFeatureFlags>;

export type CompanyFeatureFlagNames = keyof CompanyFeatureFlags;

export type CompanyFeatureFlagsRegistry = Defined<CompanyFeatureFlags>;

export interface FeatureFlagsAtom {
  company: CompanyFeatureFlags;
}

export const clientUIFeatureFlagsAtom = atomWithReset<FeatureFlagsAtom>({ company: {} });

export const useCompanyFeatureFlag = (featureFlag: CompanyFeatureFlagNames) => {
  const featureFlags = useAtomValue(clientUIFeatureFlagsAtom);

  if (featureFlag in featureFlags.company) {
    return Boolean(featureFlags.company[featureFlag]);
  }

  return false;
};

export const getCompanyFeatureFlag = (featureFlag: CompanyFeatureFlagNames) => {
  const featureFlags = AppStore.store.get(clientUIFeatureFlagsAtom);

  if (featureFlag in featureFlags.company) {
    return Boolean(featureFlags.company[featureFlag]);
  }

  return false;
};

export const useUpdateCompanyFeatureFlags = () => {
  const [, setFeatureFlags] = useAtom(clientUIFeatureFlagsAtom);

  return useCallback(
    (newFeatureFlags: CompanyFeatureFlags) => {
      setFeatureFlags((currFeatureFlags) => {
        const isChanged = Object.entries(newFeatureFlags).some(([flagName, newFlagValue]) => {
          const flagValue = currFeatureFlags.company[flagName as CompanyFeatureFlagNames];
          return newFlagValue !== flagValue;
        });

        // Only update feature flags if `newFeatureFlags` has changed
        if (isChanged) {
          return {
            company: {
              ...currFeatureFlags.company,
              ...newFeatureFlags,
            },
          };
        }

        return currFeatureFlags;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFeatureFlags]
  );
};

export const updateCompanyFeatureFlags = (newFeatureFlags: CompanyFeatureFlags) => {
  const currFeatureFlags = AppStore.store.get(clientUIFeatureFlagsAtom);

  const isChanged = Object.entries(newFeatureFlags).some(([flagName, newFlagValue]) => {
    const flagValue = currFeatureFlags.company[flagName as CompanyFeatureFlagNames];
    return newFlagValue !== flagValue;
  });

  // Only update feature flags if `newFeatureFlags` has changed
  if (isChanged) {
    AppStore.store.set(clientUIFeatureFlagsAtom, {
      company: {
        ...currFeatureFlags.company,
        ...newFeatureFlags,
      },
    });
  }
};

export const getClientUIFeatureFlagFragment = (queryRef: ClientUIFeatureFlagsFragment_query$key) =>
  readInlineData(ClientUIFeatureFlagsFragment, queryRef);

export const useClientUIFeatureFlagFragment = (
  queryData: ClientUIFeatureFlagsFragment_query$key
) => {
  return getClientUIFeatureFlagFragment(queryData);
};
