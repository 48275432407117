import {
  ChatStatusIndicator,
  LiveChat,
  LiveChatText,
  RaisedUnreadChatBadge,
  ZendeskPopoverContent,
} from './styledComponents';
import { Popover } from '@attentive/picnic';
import React from 'react';
import { IndicatorProps } from './types';

export const ChatIndicatorText = ({ onClick, unreadCount, active, ...rest }: IndicatorProps) => {
  const [showPopover, setShowPopover] = React.useState(false);

  return (
    <LiveChat onClick={onClick} {...rest}>
      <ChatStatusIndicator active={active} css={{ mr: '$space2' }} />
      <Popover open={showPopover}>
        <Popover.Trigger>
          <LiveChatText
            onMouseOver={() => setShowPopover(true)}
            onMouseOut={() => setShowPopover(false)}
            data-nav-id="live-chat-button"
          >
            Live chat
            {unreadCount > 0 && (
              <RaisedUnreadChatBadge unreadCount={unreadCount} css={{ marginTop: -4 }} />
            )}
          </LiveChatText>
        </Popover.Trigger>
        <ZendeskPopoverContent />
      </Popover>
    </LiveChat>
  );
};
