import {
  mswGraphql,
  createConnection,
  MSW_DELAY_HEADER,
  parseMSWHeaders,
} from '@attentive/mock-data';

import {
  matchingMockCompanies,
  pageNumberOfMockCompanies,
  buildMockSelectedCompanyResponse,
} from './company-search-mock-helpers';

import {
  CompanySearchDropdownAcoreUtilsQuery$rawResponse,
  CompanySearchDropdownAcoreUtilsQuery$variables,
} from './__generated__/CompanySearchDropdownAcoreUtilsQuery.graphql';

export interface CompanySearchDropdownAcoreUtilsQueryMSWHeaders {
  [MSW_DELAY_HEADER]?: number;
}

export const CompanySearchDropdownAcoreUtilsQueryMock = mswGraphql.query<
  CompanySearchDropdownAcoreUtilsQuery$rawResponse,
  CompanySearchDropdownAcoreUtilsQuery$variables
>('CompanySearchDropdownAcoreUtilsQuery', (req, res, ctx) => {
  const { count, companyId, filter, cursor, hasSelectedCompany } = req.variables;
  const mswHeaders = parseMSWHeaders<CompanySearchDropdownAcoreUtilsQueryMSWHeaders>(req.headers);

  const companies = matchingMockCompanies(filter?.searchTerm || '');
  const page = pageNumberOfMockCompanies(companies, count, cursor);

  const data = {
    companies: createConnection('Company', companies, { limit: count, page }),
    selectedCompany: buildMockSelectedCompanyResponse(hasSelectedCompany, 'id', companyId),
  };

  return res(
    ctx.delay(Number(mswHeaders[MSW_DELAY_HEADER]) ?? 0),
    ctx.data(data as unknown as CompanySearchDropdownAcoreUtilsQuery$rawResponse)
  );
});
