import { CountryCode, SupportedRegion } from '@attentive/locale-utils';
import { useCompanyShortName } from './useCompanyShortName';
import { useTagCdnDomainSuffix } from '../atoms';

export const useTagUrl = (
  attentiveDomain?: string,
  country: SupportedRegion | string = CountryCode.US
): string => {
  const isDev = useTagCdnDomainSuffix().includes('.dev');

  return `https://cdn${isDev ? '.dev' : ''}.attn.tv/${useCompanyShortName(
    attentiveDomain,
    country
  )}/dtag.js`;
};
