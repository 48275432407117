import { History } from 'history';
import { Environment } from 'react-relay';

import { AuthUser } from '@attentive/data';

export const CLIENT_UI_PROJECT_NAME = 'client-ui';
export const LOGIN_UI_PROJECT_NAME = 'login-ui';
export const MOBILE_WALLET_UI_PROJECT_NAME = 'mobile-wallet-ui';

export interface AcoreAppInitProps {
  el: HTMLElement;
  history: History;
  baseURL: string;
  currentUser: AuthUser;
  relayEnvironment: Environment;
}

export type AppKeys =
  | typeof CLIENT_UI_PROJECT_NAME
  | typeof LOGIN_UI_PROJECT_NAME
  | typeof MOBILE_WALLET_UI_PROJECT_NAME;

export type LazyMfeKeys =
  | 'admin-center-ui'
  | 'analytics-ui'
  | 'brand-kit-ui'
  | 'concierge-ui'
  | 'conversations-ui'
  | 'crm-ui'
  | 'home-ui'
  | 'integrations-ui'
  | 'journeys-ui'
  | 'messages-ui'
  | 'offers-ui'
  | 'onboarding-ui'
  | 'reference-ui'
  | 'segmentation-ui'
  | 'settings-ui'
  | 'subscribers-ui'
  | 'tactical-ui'
  | 'tag-ui'
  | 'email-editor-test-harness-ui'
  | 'ml-ui'
  | 'templates-ui';

export interface LazyMfeProps {
  mfeName?: LazyMfeKeys;
}
