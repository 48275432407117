import { CountryCode } from '../constants/countryCode';
import { SupportedRegion } from '../constants/supportedRegions';
import { LocaleConfig } from '../constants/types';
import { Locale } from '../constants/locale';
import {
  LocaleConfigUK,
  LocaleConfigUS,
  LocaleConfigCA,
  LocaleConfigAU,
  LocaleConfigFR,
  LocaleConfigIT,
  LocaleConfigIE,
  LocaleConfigES,
  LocaleConfigNL,
  LocaleConfigSE,
  LocaleConfigPT,
  LocaleConfigDE,
  LocaleConfigBE,
  LocaleConfigDK,
  LocaleConfigPL,
  LocaleConfigNO,
  LocaleConfigCH,
  LocaleConfigBR,
  LocaleConfigMX,
  LocaleConfigNZ,
} from '../locale-configs';

const localeConfigs: Record<SupportedRegion, LocaleConfig> = {
  [CountryCode.US]: LocaleConfigUS,
  [CountryCode.CA]: LocaleConfigCA,
  [CountryCode.GB]: LocaleConfigUK,
  [CountryCode.AU]: LocaleConfigAU,
  [CountryCode.FR]: LocaleConfigFR,
  [CountryCode.IT]: LocaleConfigIT,
  [CountryCode.IE]: LocaleConfigIE,
  [CountryCode.ES]: LocaleConfigES,
  [CountryCode.NL]: LocaleConfigNL,
  [CountryCode.SE]: LocaleConfigSE,
  [CountryCode.PT]: LocaleConfigPT,
  [CountryCode.BE]: LocaleConfigBE,
  [CountryCode.DE]: LocaleConfigDE,
  [CountryCode.DK]: LocaleConfigDK,
  [CountryCode.PL]: LocaleConfigPL,
  [CountryCode.NO]: LocaleConfigNO,
  [CountryCode.CH]: LocaleConfigCH,
  [CountryCode.BR]: LocaleConfigBR,
  [CountryCode.MX]: LocaleConfigMX,
  [CountryCode.NZ]: LocaleConfigNZ,
};

export const getLocaleConfig = (countryCode: CountryCode = CountryCode.US): LocaleConfig =>
  localeConfigs[countryCode as SupportedRegion] || LocaleConfigUS;

export const getLocale = (countryCode: CountryCode = CountryCode.US): Locale =>
  getLocaleConfig(countryCode).locale;
