import { CountryCode, SupportedRegion } from '@attentive/locale-utils';
import { useTagCdnDomainSuffix } from '../atoms';

const COUNTRY_DOMAIN_CODES: Record<SupportedRegion, string> = {
  [CountryCode.GB]: '-gb',
  [CountryCode.CA]: '-ca',
  [CountryCode.AU]: '-au',
  [CountryCode.FR]: '-fr',
  [CountryCode.IE]: '-ie',
  [CountryCode.IT]: '-it',
  [CountryCode.NL]: '-nl',
  [CountryCode.ES]: '-es',
  [CountryCode.SE]: '-se',
  [CountryCode.PT]: '-pt',
  [CountryCode.DE]: '-de',
  [CountryCode.BE]: '-be',
  [CountryCode.DK]: '-dk',
  [CountryCode.PL]: '-pl',
  [CountryCode.NO]: '-no',
  [CountryCode.CH]: '-ch',
  [CountryCode.BR]: '-br',
  [CountryCode.MX]: '-mx',
  [CountryCode.NZ]: '-nz',
  [CountryCode.US]: '',
};

export function useRegionalizedAttentiveDomainSuffix(
  country: SupportedRegion | string = CountryCode.US
) {
  const tagCdnDomainSuffix = useTagCdnDomainSuffix();
  return `${COUNTRY_DOMAIN_CODES[country as SupportedRegion] || ''}${tagCdnDomainSuffix}`;
}
