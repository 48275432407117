export const MARK_MFE_LOADED = 'mfeLoaded';
export const MARK_CLIENT_UI_STARTED = 'client-ui: init started';
export const MARK_CLIENT_UI_RENDERING = 'client-ui: rendering app';
export const MARK_FETCHING_LEGACY_DATA = 'attentivemobile: fetching legacy data';
export const MARK_FETCHING_LEGACY_DATA_COMPLETE = 'attentivemobile: fetching legacy data complete';
export const MARK_ACORE_COMPONENT = 'acore: ComponentLoader:';
export const EMAIL_EDITOR_INIT = 'email-core: email editor initialized';
export const SAVE_EMAIL_INIT = 'email-core: save email initialized';
export const DELETE_SAVED_ROW_INIT = 'email-core: delete saved row initialized';
export const CREATE_SAVED_ROW_INIT = 'email-core: save saved row initialized';
export const SAVE_TEMPLATE_INIT = 'email-core: save template initialized';
export const EMAIL_EDITOR_PRELOAD = 'email-core: email editor preloaded';
export const EMAIL_EDITOR_PRELOAD_USAGE = 'email-core: email editor preload usage';

export const performanceMark = (key: string) => {
  // This is a core web browser feature and should exist in all browsers.
  if (window.performance && window.performance.mark) {
    window.performance.mark(key);
  }
};

export const getPerformanceMarksByName = (name: string) => {
  if (window.performance && !!window.performance.getEntriesByName) {
    return window.performance.getEntriesByName(name);
  }
};
