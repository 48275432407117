import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigDE: LocaleConfig = {
  locale: Locale.deDE,
  currencyConfig: {
    currencyCode: CurrencyCode.DE,
  },
  optOutLanguageRequired: false,
};
