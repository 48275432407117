import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigPL: LocaleConfig = {
  locale: Locale.plPL,
  currencyConfig: {
    currencyCode: CurrencyCode.PL,
  },
  optOutLanguageRequired: false,
};
