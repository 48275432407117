import { atom, useSetAtom, useAtomValue } from 'jotai';

export const legacyInboundWebhookServiceBaseUrlAtom = atom<string>('https://example.com');
export const centralizedInboundWebhookServiceBaseUrlAtom = atom<string>('https://example-2.com');
export const shopifyAppEmbedIdAtom = atom<string>('');

export const useSetLegacyInboundWebhookServiceBaseUrl = () =>
  useSetAtom(legacyInboundWebhookServiceBaseUrlAtom);
export const useLegacyInboundWebhookServiceBaseUrl = () =>
  useAtomValue(legacyInboundWebhookServiceBaseUrlAtom);
export const useSetCentralizedInboundWebhookServiceBaseUrl = () =>
  useSetAtom(centralizedInboundWebhookServiceBaseUrlAtom);
export const useCentralizedInboundWebhookServiceBaseUrl = () =>
  useAtomValue(centralizedInboundWebhookServiceBaseUrlAtom);
export const useShopifyAppEmbedId = () => useAtomValue(shopifyAppEmbedIdAtom);
