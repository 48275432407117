import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { checkPermission, usePermissions } from '../atoms';
import { Permission } from '@attentive/data';

type RoutePermissionsProps = {
  permission: Permission | ((checkPermission: (permission: Permission) => boolean) => boolean);
  children?: never;
};
/**
 * Renders child routes if the user is authorized to access the current route.
 * If the user is not authorized, redirects to a 404 page with the current pathname as a query parameter.
 * @param {Object} props - The component props.
 * @param {string | function} props.permission - The required permission to access the route or a predicate to use to check permissions.
 * @returns {JSX.Element} - The rendered component.
 */
export function RoutePermissions({ permission }: RoutePermissionsProps) {
  const permissionsRecord = usePermissions();

  const isAuthorized =
    typeof permission === 'function'
      ? permission(checkPermission(permissionsRecord))
      : checkPermission(permissionsRecord)(permission);

  const { pathname } = useLocation();
  return isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate replace to={`/404?origin=${encodeURIComponent(pathname)}`} />
  );
}
