import React, { useEffect } from 'react';
import { Route, RouteProps, Outlet } from 'react-router-dom';

import { useSetAtom } from 'jotai';

import { fullscreenModeAtom } from '../atoms/full-screen-atom';

type OutletProps = {
  children: never;
};
// TODO-RR6: return Outlet only when migration is complete
export const FullScreenRoute = (props: RouteProps | OutletProps) => {
  const setFullscreenMode = useSetAtom(fullscreenModeAtom);
  useEffect(() => {
    setFullscreenMode(true);

    return () => {
      setFullscreenMode(false);
    };
  }, [setFullscreenMode]);

  return props.children ? <Route {...props} /> : <Outlet />;
};
