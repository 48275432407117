import { useAtomValue } from 'jotai';

import { AuthUser } from '@attentive/data';

import { currentUserAtom } from '../atoms/current-user-atom';

type CurrentUserSelector<R> = (state: AuthUser) => R;

/**
 * Returns sub-set of the AuthUser's data via a selector function.
 *
 * @public
 */
export const useCurrentUserSelector = <R>(selector: CurrentUserSelector<R>) => {
  const currentUser = useAtomValue(currentUserAtom);

  if (!currentUser) {
    throw new Error(`Current user is null`);
  }

  return selector(currentUser as unknown as AuthUser);
};

const userSelector: CurrentUserSelector<AuthUser> = (currentUser) => currentUser;

/**
 * Returns a full AuthUser object. This will re-render whenever currentUser object changes.
 *
 * @public
 */
export const useCurrentUser = (): AuthUser => {
  return useCurrentUserSelector(userSelector);
};
