import { Config } from '../Config';

/**
 * Loads a CSS file by URL returning a promise
 */
export const loadCSS = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const linkTagExists = document.querySelector(`link[href="${url}"]`);
    if (!linkTagExists && Config.get('bundledWithEsbuild')) {
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = url;
      link.onload = () => resolve(true);
      document.head.appendChild(link);
    } else {
      resolve(true);
    }
  });
};
