import { createNode, MSW_DELAY_HEADER } from '@attentive/mock-data';
import { Company } from '@attentive/data/types';

export interface CompanySearchDropdownAcoreUtilsQueryMSWHeaders {
  [MSW_DELAY_HEADER]?: number;
}

const ASCII_A_STARTING_INDEX = 65;

type MockCompany = Pick<Company, 'id' | 'internalId' | 'name'>;

const MOCK_COMPANIES: MockCompany[] = Array.from({ length: 26 * 26 }).map((_, i) => {
  const firstLetterIndex = Math.floor(i / 26) % 26;
  const secondLetterIndex = i % 26;

  const companyLetters =
    String.fromCharCode(firstLetterIndex + ASCII_A_STARTING_INDEX) +
    String.fromCharCode(secondLetterIndex + ASCII_A_STARTING_INDEX);

  return createNode('Company', {
    id: `company-${companyLetters.toLowerCase()}`,
    internalId: i + 1,
    name: `Company ${companyLetters}`,
  });
});

export const matchingMockCompanies = (query: string) => {
  return MOCK_COMPANIES.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()));
};

export const pageNumberOfMockCompanies = (
  companies: MockCompany[],
  count: number,
  cursor?: string | null
) => {
  if (cursor) {
    return Math.floor((companies.findIndex(({ id }) => id === cursor) + 1) / count);
  }

  return 0;
};

export const buildMockSelectedCompanyResponse = (
  hasSelectedCompany: boolean,
  fieldName: 'internalId' | 'id',
  id: string | number
) => {
  if (hasSelectedCompany) {
    const selectedCompany = MOCK_COMPANIES.find((company) => company[fieldName] === id);

    if (selectedCompany) {
      return createNode('Company', {
        id: selectedCompany.id,
        internalId: selectedCompany.internalId,
        name: selectedCompany.name,
      });
    }
  }
  return null;
};
