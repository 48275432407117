import { useProjectName } from '../Logger';
import { LazyMfeKeys } from '../types';

/**
 * This attribute should be rendered on an element within the MFE,
 * and is used to verify that the MFE content has loaded.
 *
 * The hook accesses the same mfeName value passed as a prop to the top level MFE component.
 * Use it to avoid prop-drilling if the component you want to render the attribute on is further down the hierarchy.
 */
export const MFE_NAME_ATTRIBUTE = 'data-mfe-name';

export const getMfeNameProps = (mfeName?: LazyMfeKeys) => {
  if (!mfeName) {
    return;
  }

  return {
    [MFE_NAME_ATTRIBUTE]: mfeName,
  };
};

export const useMfeNameProps = () => {
  const mfeName = useProjectName();

  return getMfeNameProps(mfeName as LazyMfeKeys);
};
