import { CurrencyCode } from '../constants/currency';
import { Locale } from '../constants/locale';
import { LocaleConfig } from '../constants/types';

export const LocaleConfigCH: LocaleConfig = {
  locale: Locale.deCH,
  currencyConfig: {
    currencyCode: CurrencyCode.CH,
  },
  optOutLanguageRequired: true,
};
